import React from "react";

import * as styles from "./initiative.module.css";

function Initiative(props) {
  const { id, website, name, tagline } = props;
  return (
    <li key={id} className={styles.initiativeItem}>
      <div className={styles.initiativeHead}>
        {website && (
          <a href={website} target="_blank" rel="noopener noreferrer">
            <h4 className={styles.title}>{name}</h4>
            <div>{website}</div>
          </a>
        )}
      </div>
      <div className={styles.initiativeDescription}>{tagline}</div>
    </li>
  );
}

export default Initiative;
