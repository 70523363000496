// extracted by mini-css-extract-plugin
export var categoriesHeadline = "main-topic-module--categoriesHeadline--G-aVb typography-module--base--FphVn";
export var description = "main-topic-module--description--FHAEW";
export var hero = "main-topic-module--hero--VVyQr category-preview-module--title--kfGFp";
export var initiatives = "main-topic-module--initiatives--0VAin";
export var mainContent = "main-topic-module--mainContent--xraCl";
export var mainTopic1 = "main-topic-module--mainTopic1--G++nm";
export var mainTopic2 = "main-topic-module--mainTopic2--VTMwp";
export var mainTopic3 = "main-topic-module--mainTopic3--umUPy";
export var mainTopic4 = "main-topic-module--mainTopic4--fj3xG";
export var mainTopic5 = "main-topic-module--mainTopic5--nl0fs";
export var mainTopic6 = "main-topic-module--mainTopic6--pzrG0";
export var mainTopic7 = "main-topic-module--mainTopic7--oz-FI";
export var mainTopic8 = "main-topic-module--mainTopic8---7qbl";
export var root = "main-topic-module--root--nuXAp";
export var title = "main-topic-module--title--ekCKR";
export var topSection = "main-topic-module--topSection--1XeIg";