import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Initiative from "../components/initiative";

import * as styles from "../components/main-topic.module.css";

export const query = graphql`
  query InitiativesQuery {
    initiatives: allSanityInitiative(sort: { fields: slug___current }) {
      edges {
        node {
          name
          id
          website
          tagline
          displayPosition
          mainTopics {
            id
            title
            slug {
              current
            }
          }
        }
      }
    }
  }
`;

const InitiativesPage = (props) => {
  const { data, errors } = props;
  const initiatives = data && data.initiatives.edges.map((x) => x.node).filter((x) => x.website);
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      <SEO title="Alle Initiativen alphabetisch" />

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {initiatives && initiatives.length > 0 && (
        <article className={styles.root}>
          <Container>
            <div className={styles.mainContent}>
              <h1>Liste aller Initiativen alphabetisch</h1>

              <div className={styles.initiatives}>
                <ul>
                  {initiatives.map((initiative) => (
                    <Initiative key={initiative.id} {...initiative}></Initiative>
                  ))}
                </ul>
              </div>
            </div>
          </Container>
        </article>
      )}
    </Layout>
  );
};

export default InitiativesPage;
